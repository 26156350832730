<template>
    <div class="content">
        <router-view></router-view>
        <div class="top-img">
            <div class="banner-img" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="content-1200">
            <div class="yi">
                <div class="yi-left">
                    <div class="swiper-wrap">
                        <swiper ref="swiperDom" :options="swiperOption1">
                            <swiper-slide v-for="(item,index) in imgNews" :key="index">
                                <img :src="item.files" alt  @click="cookie(item.id,'409','',item.isrole)" style="cursor: pointer;"/>
                                <div class="swiper-text" :title="item.title" style="width: 100%;
                                    padding-right:100px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;">
                                    <span style="margin-left:20px;">{{item.title}}</span>
                                </div>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                    </div>
                </div>
                <div class="yi-center">
                    <!-- <template>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane v-for="(tabs,key) in tabsData" :key="key" :label="tabs.title" :name="tabs.name" >
                                <ul v-for="(item,i) in xxxwData" :key="i">
                                    <li @click="hrefUrlDetails(item.id,tabs.name,tabs.title)">
                                        <p>{{item.title}}</p>
                                        <p>{{item.ctime | timeLenght }}</p>
                                    </li>
                                </ul>
                            </el-tab-pane>
                        </el-tabs>
                    </template> -->
                    <ul class="hoverTabs">
                        <li><span v-bind:class="{current: num == 1}" @mouseover="change(1)">学校新闻</span></li>
                        <li><span v-bind:class="{current: num==2}" @mouseover="change(2)">部门动态</span></li>
                        <li><span v-bind:class="{current: num==3}" @mouseover="change(3)">校务公开</span></li>
                        <li><span v-bind:class="{current: num==4}" @mouseover="change(4)">工作计划</span></li>
                    </ul>
                    <div class="hoverTabs-content">
                        <transition name="fade" v-for="(item,i) in xxxwData1" :key="i">
                            <div v-show="num == 1" @click="cookie(item.id,'409','学校新闻',item.isrole)">
                                <p class="ell">{{item.title}}</p>
                                <p>{{item.ctime | timeLenght }}</p>
                            </div>
                        </transition>
                        <transition name="move" v-for="(item,i) in xxxwData2" :key="i">
                            <div v-show="num == 2" @click="cookie(item.id,'409','部门动态',item.isrole)">
                                <p class="ell">{{item.title}}</p>
                                <p>{{item.ctime | timeLenght }}</p>
                            </div>
                        </transition>
                         <transition name="fade" v-for="(item,i) in xxxwData3" :key="i">
                            <div v-show="num == 3" @click="cookie(item.id,'409','校务公开',item.isrole)">
                                <p class="ell">{{item.title}}</p>
                                <p>{{item.ctime | timeLenght }}</p>
                            </div>
                        </transition>
                         <transition name="fade" v-for="(item,i) in xxxwData4" :key="i">
                            <div v-show="num == 4" @click="cookie(item.id,'409','工作计划',item.isrole)">
                                <p class="ell">{{item.title}}</p>
                                <p>{{item.ctime | timeLenght }}</p>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="yi-right">
                    <p style="color:#bb291d;font-size:18px;display:flex;">
                        <img src="static/jr/dongtai.png" alt="" style="width:25px;height:25px;margin-right:5px;">
                        <span>学校动态</span>
                    </p>
                    <div class="school-dynamic" v-for="(item,i) in xxdt" :key="i">
                        <div class="school-dynamic-left">
                            <p class="dynamic-left-year">{{item.ctime1}}年{{item.ctime2}}月</p>
                            <p class="dynamic-left-date">{{item.ctime3}}日</p>
                        </div>
                        <div class="school-dynamic-right" @click="cookie(item.id,'409','学校动态',item.isrole)">
                            <p>{{item.title}}</p>
                            <!-- <p style="color: #666;">{{item.title}}</p> -->
                        </div>
                    </div>
                    <div class="notice">
                        <div class="notice-left">
                            <p style="padding-top: 5px;">网站</p>
                            <p>公告</p>
                        </div>
                        <swiper :options="gonggao">
                            <swiper-slide v-for="(item,i) in wzgg" :key="i" >
                                <p class="notice-text" @click="cookie(item.id,'409','',item.isrole)">{{item.title}}</p>
                            </swiper-slide>
                            <!-- <swiper-slide>
                                <p class="notice-text">关于更新校园说明关于更新校园说明关于更新校园说明</p>
                            </swiper-slide> -->
                            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                            <div class="swiper-button-prev swp3" slot="button-prev">
                            <img src="static/jr/bottomswiper.png" alt="" style="width: 20px;height: 20px;">
                            </div>
                            <div class="swiper-button-next swp3" slot="button-next">
                            <img src="static/jr/topswiper.png" alt="" style="width: 20px;height: 20px;">
                            </div>
                        </swiper>
                    </div>
                </div>
            </div>
            <div class="five" style="position:relative;">
                <div class="tip">
                    <span style="color: #c03f31;padding-right:12px;"><img src="static/jr/xyfm.png" alt="" class="tip-icon"> 应用中心</span>
                </div>
                <swiper ref="swiperDom2" :options="swiperOption2" style="margin:20px 0;" v-for="(item,i) in yycenter" :key="i">
                    <swiper-slide  v-for="(list,index) in item.stypes" :key="index" @click.native="hrefUrl(list.tzurl)">
                        <div href="#" style="cursor: pointer;">
                            <img :src="list.bgimg" alt="" class="department-icon">
                            <p>{{list.title}}</p>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-next swp2"></div>
                <div class="swiper-button-prev swp2"></div>
            </div>
            <div class="friendLinks">
                <div style="display:flex;line-height:34px;">
                    <img src="static/jr/links.png" alt="" class="links-icon">
                    <span>友情链接</span>
                </div>
                <div v-for="(item, i) in friendshipLinks" :key="i">
                    <el-select v-model="item.title" :placeholder="item.title">
                        <el-option
                            v-for="(val, index) in item.urls"
                            :key="index"
                            :label="val.title"
                            :value="val.title"
                            @click.native="hrefUrl(val.url)"
                            >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="350px">
            <p>您没有权限，请先登录！
                <el-button type="primary" style="margin-left:10px;" @click="login">登录</el-button>
            </p>
        </el-dialog>
    </div>
</template>
<script>
import JrApi from '../../../lvya-common/src/api/eos/jr'
import DoUrl from '@/lvya-common/src/utils/DoUrl'
import Login from '@/lvya-common/src/api/eos/Login'
export default {
    components: {
    },
    data() {
        return {
            activeName: '410',
            tabsData: [
              {
                title: '学校新闻',
                name: '410'
              },
              {
                title: '部门动态',
                name: '414'
              },
              {
                title: '校务公开',
                name: '411'
              },
              {
                title: '工作计划',
                name: '412'
              }
            ],
            num: 1,
            imgNews: [],
            xxxw: [],
            bmdt: [],
            xwgk: [],
            gzjh: [],
            yycenter: [],
            banner: '',
            friendshipLinks: [],
            xxdt: [],
            xxxwData: [],
            xxxwData1: [],
            xxxwData2: [],
            xxxwData3: [],
            xxxwData4: [],
            wzgg: [],
            // 图文轮播
            swiperOption1: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, // 允许点击小圆点跳转
                    renderBullet: function(index, className) {
                        // console.log(index, className)
                        return '<span class="' + className + '">' + (index + 1) + '</span>'
                    }
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false // 手动切换之后继续自动轮播
                },
                loop: true
            },
            // 公告
            gonggao: {
                pagination: {
                    clickable: true // 允许点击小圆点跳转
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false // 手动切换之后继续自动轮播
                },
                loop: true,
                observer: true,
                direction: 'vertical',
                navigation: {
                    nextEl: '.swiper-button-next.swp3',
                    prevEl: '.swiper-button-prev.swp3'
                }
            },
            swiperOption2: {
                slidesPerView: 7, // 数量
                spaceBetween: 70, // 间距
                // slidesPerGroup: 5,
                // observer: true,
                // observeParents: true,
                // pagination: {
                // //   el: '.swiper-pagination',
                // //   clickable: true, // 允许点击小圆点跳转
                // },
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false // 手动切换之后继续自动轮播
                },
                // loopFillGroupWithBlank: true,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next.swp2',
                    prevEl: '.swiper-button-prev.swp2'
                }
            },
            value: '',
            dialogVisible: false,
        }
    },
    async created() {
        if (location.href.includes('trustCode=')) {
            let trustCode = DoUrl.getSearchByName('trustCode', location.href)
            const loginInfo = await Login.getToken({ authorizationCode: trustCode, schoolId: 38 })
            Cookies.set('EOSToken', loginInfo.token)
            location.href = location.origin
        }
        this.ImgNews()
        this.Xxxw(410)
        this.Yycenter()
        this.Banner()
        this.FriendshipLinks()
        this.Xxdt()
        this.Xxxw1()
        this.Xxxw2()
        this.Xxxw3()
        this.Xxxw4()
        this.Wzgg()
    },
    methods: {
        cookie(id, pid, tit, isrole) {
            if (isrole == 'Y') {
                let EOSToken = Cookies.get('EOSToken')
                if (EOSToken) {
                    this.$router.push({ path: '/indexDetails2', query: { id, pid: 409, tit }})
                } else {
                    this.id = id
                    this.dialogVisible = true
                }
            } else {
                this.$router.push({ path: '/indexDetails2', query: { id, pid: 409, tit }})
            }
        },
        login() {
            window.location.href = `http://jrzz.lvya.org/eos-web/#/login?url=http://www.jsjrzz.net/#/indexDetails2?id=${this.id}&pid=409`
        },
        // tab切换
        handleClick(tab, event) {
            // console.log(tab, event)
            this.Xxxw(tab.name)
        },
        change: function(index) {
            this.num = index
        },
        // 图文新闻
        async ImgNews() {
            const resData = await JrApi.ImgNews()
            this.imgNews = resData
            this.imgNews = resData.length > 0 ? resData.slice(0, 3) : []
            // console.log(this.imgNews)
        },
        // 跳转二级页面
        // hrefUrlDetails(id, pid, tit) {
        //     this.$router.push({ path: '/indexDetails2', query: { id, pid, tit }})
        // },
        // hrefUrlDetails2(id, pid, tit) {
        //     this.$router.push({ path: '/indexDetails2', query: { id, pid, tit }})
        // },
        // banner图
        async Banner() {
            const resData = await JrApi.Banner()
            this.banner = resData
            // console.log(this.banner)
        },
        // 首页栏目
        async Xxxw(id) {
            const resData = await JrApi.Xxxw({ typeid: id })
            this.xxxwData = resData.length < 7 ? resData : resData.slice(0, 6)
            // console.log(this.xxxwData)
        },
        async Xxxw1(id) {
            const resData = await JrApi.Xxxw({ typeid: 410 })
            this.xxxwData1 = resData.length < 7 ? resData : resData.slice(0, 6)
            // console.log(this.xxxwData1)
        },
        async Xxxw2(id) {
            const resData = await JrApi.Xxxw({ typeid: 414 })
            this.xxxwData2 = resData.length < 7 ? resData : resData.slice(0, 6)
            // console.log(this.xxxwData2)
        },
        async Xxxw3(id) {
            const resData = await JrApi.Xxxw({ typeid: 411 })
            this.xxxwData3 = resData.length < 7 ? resData : resData.slice(0, 6)
            // console.log(this.xxxwData1)
        },
        async Xxxw4(id) {
            const resData = await JrApi.Xxxw({ typeid: 412 })
            this.xxxwData4 = resData.length < 7 ? resData : resData.slice(0, 6)
            // console.log(this.xxxwData2)
        },
        // 应用中心
        async Yycenter() {
            const resData = await JrApi.Yycenter({ pid: 415 })
            this.yycenter = resData
            // console.log(this.yycenter)
        },
        // 友情链接
        async FriendshipLinks() {
            const resData = await JrApi.FriendshipLinks()
            this.friendshipLinks = resData
            // console.log(this.friendshipLinks)
        },
        // 学校动态
        async Xxdt() {
            const resData = await JrApi.Xxxw()
            resData.forEach(function(item, index) {
                item.ctime1 = item.ctime.substring(0, 4)
                item.ctime2 = item.ctime.substring(5, 7)
                item.ctime3 = item.ctime.substring(8, 10)
                // console.log(item.ctime1, item.ctime2, item.ctime3)
            })
            // for (let i = 0; i < resData.length; i++) {
            //     resData[i].content = this.escape2Html(resData[i].content)
            //     console.log(resData[i].content)
            //     this.xxdt = resData
            // }
            this.xxdt = resData.length < 3 ? resData : resData.slice(0, 2)
            // console.log(this.xxdt)
        },
        // 网站公告
        async Wzgg() {
            const resData = await JrApi.Xxxw({ typeid: 413 })
            this.wzgg = resData.length < 3 ? resData : resData.slice(0, 2)
            // console.log(this.wzgg)
        },
        // escape2Html(str) {
        //     const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' }
        //     return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) { return arrEntities[t] })
        // },
        // 友情链接跳转
        hrefUrl(url) {
            // window.location.href = url
            window.open(url, '_blank')
            // this.$router.push({ path: url })
        },
        // 应用跳转
        hrefUrl2(url) {
            window.open(url, '_blank')
        },
    },
    computed: {
    },
    mounted() {
    }
}
</script>

<style scope>
.content {
  width: 100%;
  background: #fff;
}
.top-img{
  width: 100%;
  height: 410px;
  min-width: 1200px;
}
.banner-img {
    width: 100%;
    height: 410px;
    min-width: 1200px;
    /* background: url('../../../../public/static/jr/banner2.png') center top no-repeat; */
    /* background-size: 100% 100%; */
}
.top-img img{
    width: 100%;
    height: 100%;
}
.content-1200 {
  width: 1200px;
  min-height: 500px;
  margin: 20px auto;
}
.yi{
  width: 100%;
  min-height: 200px;
  background: #fff;
  display: flex;
  margin: 20px 0;
}
.yi-center{
  flex: 1;
  /* width: 460px; */
  background: #fff;
  margin: 0 20px;
}
.yi-center ul{
  width: 100%;
}
.yi-center ul li{
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
}
.yi-center ul li:hover{
    color: #891d24;
    cursor: pointer;
}
.yi-center ul li p:first-child{
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 10px;
}
.el-tabs__item.is-active{
  color: #891d24;
}
.el-tabs__active-bar{
  background-color: #891d24;
}
.el-tabs__item:hover{
  color: #891d24;
}
.el-tabs__item{
  font-size: 16px;
}
.yi-right{
  width: 300px;
  min-height: 100px;
  background: #fff;
  /* margin-top: -110px; */
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
}
.school-dynamic{
  width: 100%;
  display: flex;
  padding: 10px 0;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
}
.dynamic-left-year{
  background: #bc2715;
  color: #fff;
  padding: 2px 5px;
  font-size: 10px;
}
.dynamic-left-date{
  font-size: 18px;
  text-align: center;
  color: #bb291d;
  padding: 5px 0;
}
.school-dynamic-left {
    margin-right: 10px;
}
.school-dynamic-right {
    width: 65%;
    font-size: 14px;
    line-height: 24px;
}
.school-dynamic-right p {
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.notice{
    width: 100%;
    height: 60px;
    display: flex;
}
.notice-left{
    width: 60px;
    height: 60px;
    background: #bc2715;
    font-size: 18px;
    color: #fff;
    text-align: center;
}
.notice .swiper-container{
    width: 80%;
    height: 60px;
    border: 1px solid #eee;
    position: relative;
}
.notice .swiper-button-next{
    position: absolute;
    top: 15%;
    right: 0;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
    outline: none;
    background-image:none;
}
.notice .swiper-button-prev{
    position: absolute;
    top: 55%;
    right: 0;
    left: auto;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
    outline: none;
    background-image:none;
}
.notice-text{
    width: 80%;
    font-size: 12px;
    text-align: left;
    padding-right: 20px;
    line-height: 22px;
    cursor: pointer;
}
.swiper-wrap{
    width: 300px;
    height: 240px;
    background: #fff;
    position: relative;
}
.swiper-container {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.swiper-slide img{
    width: 100%;
    height: 100%;
}
.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color:#000;
    opacity: 1;
    background: #fff;
    z-index: 999;
}
.swiper-pagination-bullet-active {
    color:#fff;
    background: #b72a17;
}
.swiper-pagination{
    text-align: right;
    /* background: rgba(0,0,0,.5); */
    bottom: 0!important;
    padding: 5px 0;
}
.swiper-pagination-bullet{
    border-radius: 25%;
}
.swiper-text{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    z-index: 99;
    color: #fff;
    line-height: 30px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}
.department-icon{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 5px;
}
.five{
    margin-top: 10px;
}
.swiper-button-next,.swiper-button-prev{
    z-index: 999;
}
.swiper-button-next{
    right: 20px;
    outline: none;
    top: 125px;
}
.swiper-button-prev{
    left: 20px;
    outline: none;
     top: 125px;
}
.five .swiper-slide img{
    width: 80%;
}
.five .swiper-slide a{
  text-decoration: none;
  color: #333;
}
.five .swiper-container{
  width: 90%;
  margin: 50px auto!important;
}
.weekWorktop{
    background: #bb2a17 ;
    color: #fff;
}
.tip-icon{
    width: 18px;
    height: 18px;
    margin-bottom: -3px;
    margin-left: 10px;
}
.friendLinks{
  width: 100%;
  display: flex;
}
.links-icon{
    width: 20px;
    height: 20px;
    margin-top: 7px;
    margin-right: 5px;
}
.friendLinks .el-select{
  margin: 0 30px;
}
.el-input__inner{
  border: 1px solid #ccc;
}
.el-input__inner::-webkit-input-placeholder{
    color:#666;
}
.el-input__inner::-moz-placeholder{
    color:#666;
}
.el-input__inner:-moz-placeholder{
    color:#666;
}
.el-input__inner:-ms-input-placeholder{
    color:#666;
}
p{
    margin-block-start: 0;
    margin-block-end: 0;
}
.hoverTabs {
  height: 50px;
  display: flex;
}
.hoverTabs li {
  float: left;
  width: 100px!important;
  height: 30px;
  border-color: #000;
  cursor: pointer;
}
.hoverTabs-content div{
    display: flex;
    justify-content: space-between;
    line-height: 30px;
}
.hoverTabs-content div .ell{
    width: 450px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    padding-left: 0;
}
.hoverTabs-content div:hover{
    color: #891d24;
    cursor: pointer;
}
.current {
  color: #891d24;
  border-bottom: 2px solid #891d24;
}
</style>
